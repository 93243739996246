.tiny-image {
  width: 20px;
}

.small-image {
  width: 25%;
}

.medium-image {
  width: 50%;
}

.large-image {
  width: 75%;
}

.full-image {
  @apply w-full;
}

.text-centre img, .text-center figure {
    @apply mx-auto;
}

figure img {
  width: 100%;
}

.stack-cell-valign-middle {
  figure {
    @apply mx-auto;
  }
}