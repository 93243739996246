%overlay-default {
    content: '';
    height: 2px;
    background: #f3f3f3;
    top: -2px;
    position: absolute;
}

.cc-people-item-button {
    transition: all 0.3s ease-in-out;

    &:hover svg path {
        stroke: #ffffff;
    }
}

.cc-people-item {
    &__detail {
        opacity: 0;
        width: 100%;
        border-bottom: 2px solid purple;
        margin-top: 10px;
        position: absolute;
        left: -99999px;
        margin-top: 30px;
        border-top: 2px solid purple;

        @screen md {
            &.overlay_3_0 {
                &:after {
                    @extend %overlay-default;
                    width: 33.3333333%;
                    left: 0;
                }
            }

            &.overlay_4_0 {
                &:after {
                    @extend %overlay-default;
                    width: 25%;
                    left: 0;
                }
            }

            @for $i from 1 through 4 {
                &.overlay_3_#{$i} {
                    &:after {
                        @extend %overlay-default;
                        width: 33.3333333%;
                        left: calc(#{$i} * 33.3333333%);
                    }
                }

                &.overlay_4_#{$i} {
                    &:after {
                        @extend %overlay-default;
                        width: 25%;
                        left: calc(#{$i} * 25%);
                    }
                }
            }
        }
    }

    &-button.is-active img {
        transform: rotate(180deg);
    }
}
