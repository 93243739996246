section.stack {
    @apply container;
    @apply mx-auto;
    @apply px-6;
    @apply py-5;
    @apply max-w-none;

    @screen lg {
        @apply py-16;
        @apply px-0;
    }

    &.valign-middle .grid {
        @apply items-center;
    }

    &.valign-bottom .grid {
        @apply items-end;
    }

    .component:not(:last-child) {
        @apply mb-6;
    }

    
}

section.stack:not([class*="bg-"]) {
    + section.stack:not([class*="bg-"]) {
        padding-top: 0;
    }
}

.cc-stack-row {
  @apply container;
  @apply mx-auto;
  @apply max-w-screen-lg;
  @apply gap-y-6;
  
  @screen lg {
    @apply gap-12;
    @apply grid-cols-12 ;
  }
}