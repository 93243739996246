/* ===== Scrollbar CSS ===== */
/* Firefox */
body {
    scrollbar-width: auto;
    scrollbar-color: #54565a #ffffff;
}

/* Chrome, Edge, and Safari */
body::-webkit-scrollbar {
    width: 16px;
}

body::-webkit-scrollbar-track {
    background: #ffffff;
}

body::-webkit-scrollbar-thumb {
    background-color: #54565a;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

body {
  background: #f3f3f3;
  @apply text-gray;
}

section {
  position: relative;
}

h2,
h3,
h4,
h5,
h6 {
  @apply font-semibold;
}

h2 {
  @apply text-3xl;
 
  @screen lg {
    @apply text-5xl;
  }
}

h3 {
  @apply text-2xl;
 
  @screen lg {
    @apply text-4xl;
  }
}

h4 {
  @apply text-xl;
 
  @screen lg {
    @apply text-3xl;
  }
}

h5 {
  @apply text-lg;
 
  @screen lg {
    @apply text-2xl;
  }
}

h6 {
  @apply text-base;
 
  @screen lg {
    @apply text-xl;
  }
}

p {
    @apply text-gray;

}


.button {
  @apply px-8;
  @apply py-2;
  @apply text-sm;
  @apply uppercase;
  @apply font-bold;
  @apply tracking-wider;
}

.cc-video {
  .cc-video-wrapper {
    @apply bg-black;

    height: 0;
    overflow: hidden;
    position: relative;
    
    iframe, video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

img {
    width:100%;

    /*@screen md {
        width:auto;
    }*/
}