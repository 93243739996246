.cc-services-link {
  @apply relative;

  font-size: 1rem;
  transition: color 0.4s ease-in-out, opacity 0.4s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @screen lg {
    font-size: 1.3rem;
  }
  
  @screen lg {
    &:before {
      @apply inline-block;
      @apply bg-purple;

      content: '';
      width: 0px;
      height: 2px;
      margin-right: 3px;
      transition: width 0.6s ease-in-out;  
    }

    &:hover {
      @apply text-purple;
      @apply opacity-100;

      &:before {
        width: 35px;
      }
    }
  }
}

.cc-services-slider {
  .glide__slide {
    max-width: 350px;
    max-height: 350px;
  }
}

.cc-service-desc {
  font-size: 3rem;
  line-height: 3.5rem;
}