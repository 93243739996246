.cc-banner {
    min-height: 400px;
    height: 60vh;

    @screen md {
        min-height: 600px;
    }

    &-text {
        width: 450px;
        max-width: 90%;
    }

    .text-white {
        opacity: 1;
    }

    @screen lg {
        a {
            position: absolute;
            bottom: 20px;
            left: 50%;
            z-index: 2;
            display: inline-block;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            color: #fff;
            text-decoration: none;
            transition: opacity .3s;
        }

        a:hover {
            opacity: .5;
        }

        .scroll {
            padding-top: 70px;

            span {
                position: absolute;
                top: 0;
                left: 50%;
                width: 24px;
                height: 24px;
                margin-left: -12px;
                border-left: 1px solid #fff;
                border-bottom: 1px solid #fff;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                -webkit-animation: sdb05 1.5s infinite;
                animation: sdb05 1.5s infinite;
            }
        }
    }



    @-webkit-keyframes sdb05 {
        0% {
            -webkit-transform: rotate(-45deg) translate(0, 0);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            -webkit-transform: rotate(-45deg) translate(-20px, 20px);
            opacity: 0;
        }
    }

    @keyframes sdb05 {
        0% {
            transform: rotate(-45deg) translate(0, 0);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            transform: rotate(-45deg) translate(-20px, 20px);
            opacity: 0;
        }
    }
}

.cc-home-banner-text {
  .cc-pre-text {
    right: 100%;
  }
  .cc-post-text {
    left: 100%;
  }
}

