.cc-careers {
    @screen md {
        grid-template-columns: minmax(80px,140px) 2fr 1fr 140px;
    }

    .cc-careers__header {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .cc-careers__box {
        position: relative;
        padding: 10px;

        @screen md {
            border-bottom: 2px solid;
            @apply border-purple;
        }
    }

    .cc-careers__boxdata {

        &:nth-child(4n) {
            border-bottom: 2px solid;
            @apply border-purple;
        }

        .cc-careers__header {
            @apply block;

            @screen md {
                @apply hidden;
            }
        }

        .cc-careers-data {

            /*@screen md {
                @apply hidden;
            }*/
        }
    }
}
