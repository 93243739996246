@import "tailwindcss/base";
@import "tailwindcss/components";

@import "@splidejs/splide/dist/css/splide-core.min";

@layer base {
  @font-face {
    font-family: 'AvertaStd';
    src: url('../fonts/AvertaStd-Regular.woff2') format('woff2'),
        url('../fonts/AvertaStd-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'AvertaStd';
    src: url('../fonts/AvertaStd-Semibold.woff2') format('woff2'),
        url('../fonts/AvertaStd-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'AvertaStd';
    src: url('../fonts/AvertaStd-Bold.woff2') format('woff2'),
        url('../fonts/AvertaStd-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
}

// PARTIALS
@import "partials/animations";

@import "partials/banner";
@import "partials/figure";
@import "partials/fullPageSlider";
@import "partials/globals";
@import "partials/header";
@import "partials/footer";
@import "partials/nav";
@import "partials/overlap-block";
@import "partials/people-viewer.scss";
@import "partials/hoverlinks";
@import "partials/rte";
@import "partials/stackedLayout";
@import "partials/video-selector";
@import "partials/careers";

@import "tailwindcss/utilities";