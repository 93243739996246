.cc-nav-icons {

    .cc-people-finder-button {
        svg {
            path {
                transition: stroke 0.3s ease-in-out;
            }
        }
    }

    &.cc-active-change {
        .cc-people-finder-close-icon {
            transition: all 0.3s ease-in-out;
        }

        .cc-people-finder-button {
            svg {
                path {
                    stroke: #8A1A9C !important;
                }
            }
        }

        .cc-nav-button {
            span {
                @apply bg-purple;

                &:before,
                &:after {
                    @apply bg-purple;
                }
            }
        }
    }
}

    .cc-nav-link {
        position: relative;
        transition: transform 0.3s ease-in-out;

        &:before {
            opacity: 0;
            content: '';
            width: 0px;
            height: 2px;
            background: white;
            position: absolute;
            left: -60px;
            bottom: 0;
            transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
        }

        @screen lg {
            &:hover {
                transform: translateX(30px);

                &:before {
                    width: 60px;
                    opacity: 1;
                }
            }
        }
    }

    .cc-nav-menu {
        @screen md {
            min-height: 800px;
        }
    }

.cc-subnav-menu {
    //-webkit-mask-image: linear-gradient(to bottom, black 0%, transparent 100%);
    //mask-image: linear-gradient(to bottom, black 0%, transparent 100%);
    //background: rgb(84,86,90);
    //background: linear-gradient(180deg, rgba(84,86,90,0) 68%, rgba(84,86,90,1) 90%);
    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
        scrollbar-width: auto;
        scrollbar-color: theme('colors.light-gray') #ffffff;
    }
    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 8px;
    }

    *::-webkit-scrollbar-track {
        @apply bg-gray;
    }

    *::-webkit-scrollbar-thumb {
        background-color: theme('colors.light-gray');
        border-radius: 8px;
        border-left: 2px solid theme('colors.gray');

        @screen lg {
            border: 0px none;
        }
    }
}