.is-rte {
    ul {
        @apply mt-2;
        @apply pl-4;
        @apply list-none;

        &:not(:last-child) {
            @apply mb-6;
        }
    }

    li {
        position: relative;

        &:before {
            content: '__';
            position: absolute;
            right: calc(100% + 3px);
            top: 0;
        }
    }

    .quote-author {
        font-size: 12px;
        line-height: 1;
        color: #5C87BC;
    }

    p:not(:last-child) {
        @apply mb-6;
    }

    a {
        @apply text-purple;
    }
}
