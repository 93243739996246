.cc-video-selector {
    grid-template-columns: 1fr 1fr;

    @screen md {
        grid-template-columns: 2fr 1fr 1fr;
        grid-template-rows: auto;
    }
}

    .cc-video-modal {
        background: rgba(0, 0, 0, 0.7);
        animation: fadeIn 0.3s ease-in-out;
        animation-direction: forwards;
    }

    .cc-video-modal-close {
        top: 2rem;
        right: 2rem;

        @apply text-white;
    }

    .cc-video-modal-button {
        .cc-play-icon {
            @apply w-6 h-6;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @screen md {
                @apply w-10 h-10
            }
        }

        img {
            opacity: 50%;
            transition: opacity 0.3s ease-in-out;
        }

        span {
            top: 75%;
            left: 10%;
            width: 80%;
            transform: translate(0, -50%);

            @apply text-left;
            @apply leading-tight;
            @apply font-bold;
            @apply text-white;
        }

        &.is-featured span {
            top: 70%;
            left: 50%;
            width: 40%;
        }

        &:hover {
            img {
                opacity: 100%;
            }
        }
    }
