header {
    transition: background 0.3s ease-in-out;

    &:not(.people-finder-active):not(.nav-active) {
        &.overlay {
            background: rgba(255,255,255,.9);
            box-shadow: 0px 10px 10px 4px rgba(138,26,156,0.1);
        }
    }

    &:not(.nav-active).overlay, &.people-finder-active {
        svg path {
            fill: #8A1A9C;
        }

        .cc-nav-button span {
            @apply bg-purple;

            &:before,
            &:after {
                @apply bg-purple;
            }
        }

        .cc-people-finder-icon {
            path {
                stroke: #8A1A9C !important;
            }
        }

        .cc-logo-image {
            filter: none;
        }

        .cc-people-finder-button {
            @apply border-purple;
        }
    }

    svg path {
        transition: fill 0.3s ease-in-out;
        fill: white;
    }

    .cc-nav-button span {
        background: white;

        &:before,
        &:after {
            background: white;
        }
    }

    .cc-logo-image {
        filter: brightness(0) invert(1);
    }
}



.cc-nav-button {
    width: 30px;
    height: 30px;
    position: relative;

    span {
        content: '';
        width: calc(80% - 10px);
        height: 2px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        transition: all 0.3s ease-in-out;

        &:before,
        &:after {
            position: absolute;
            content: '';
            height: 100%;
            left: 50%;
            transform: translate(-50%, -1px);
            transition: all 0.3s ease-in-out;
        }

        &:before {
            top: -5px;
            width: 60%;
        }

        &:after {
            top: 7px;
            width: 140%;
        }
    }

    &.is-active {
        span {
            transform: translate(-50%, 0) rotate(45deg);
            width: 60%;

            &:before {
                width: 0%;
            }

            &:after {
                width: 100%;
                transform: translate(-50%, -7px) rotate(-90deg);
            }
        }
    }
}

.cc-people-finder-button {
    width: 30px;
    height: 30px;
    position: relative;
    border: 1px solid #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        content: '';
        width: calc(80% - 10px);
        height: 2px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        transition: all 0.3s ease-in-out;

        &:before,
        &:after {
            position: absolute;
            content: '';
            height: 100%;
            left: 50%;
            transform: translate(-50%, -1px);
            transition: all 0.3s ease-in-out;
        }

        &:before {
            top: -5px;
            width: 60%;
        }

        &:after {
            top: 7px;
            width: 140%;
        }
    }

    &.is-active {
        @apply border-purple;

        span {
            transform: translate(-50%, 0) rotate(45deg);
            width: 60%;
            @apply bg-purple;

            &:before {
                width: 0%;
                @apply bg-purple;
            }

            &:after {
                width: 100%;
                transform: translate(-50%, -7px) rotate(-90deg);
                @apply bg-purple;
            }
        }
    }
}