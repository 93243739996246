.cc-page-slider-container {
  @screen lg {
        width: 100%;
    }
}

.cc-page-slider {

    section {
        @screen lg {
            min-height: 100vh;
        }
    }

    section.stack {
        @apply max-w-none;
        @apply px-0;
        @apply p-0;
    }

    @for $i from 1 to 4 {
        .cc-stack-row.cols-#{$i} {
            @screen lg {
                @apply grid-cols-#{$i};
            }
        }
    }

    .cc-stack-row {
        @apply max-w-none;
        @apply gap-0;

        @screen lg {
            @apply min-h-screen;
        }

        > div {
            @apply p-6;
            @apply justify-center;

            @screen lg {
                @apply p-0;
            }
        }

        > div[class*="col-span-"] {
            grid-column: auto;
        }

        .is-image-only {
            min-height: 200px;

            @screen md {
                min-height: none;
            }
        }
    }

    .cc-stack-row.cols-1:not(.is-full-width) {
        > div {
            @apply container;
            @apply mx-auto;
            @apply max-w-screen-xl;
        }
    }

    .cc-stack-row:not(.is-full-width):not(.cols-1) {
        > div:first-child {
            @screen lg {
                padding-left: calc((100vw - 1024px) / 2);
            }

            @screen xl {
                padding-left: calc((100vw - 1280px) / 2);
            }
        }

        > div:last-child {
            @screen lg {
                @apply pl-32;
                padding-right: calc((100vw - 1024px) / 2);
            }

            @screen xl {
                padding-right: calc((100vw - 1280px) / 2);
            }
        }
    }

    .cc-full-height-padding-left {
        padding-left: calc((100vw - 1024px) / 2);

        @screen xl {
            padding-left: calc((100vw - 1280px) / 2);
        }
    }

    .is-image-only {
        min-height: 150px;
    }
}

.cc-page-slider-status {
  @apply fixed;
  @apply flex;
  @apply flex-col;
  @apply z-20;
  @apply w-10;

  
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  span {
    @apply w-full;
    @apply bg-white;
    @apply my-4;
    @apply opacity-25;

    height: 2px;
    transition: opacity 0.4s ease-in-out;

    &.is-active {
      @apply opacity-100;
    }
  }
}